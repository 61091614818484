import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allPropertiesOfClient } from './store/propertiesSlice/propertiesSlice';
import { propertyInvoicesOfClient } from './store/invoiceSlice/invoiceSlice'
import { allSereneUsers } from './store/userSlice/userSlice';

function App() {
	// initializations
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()

	// globals
	const { accessToken, userSliceLoading, id, isAdmin } = useSelector(state => state.user);
	const { propertiesSliceLoading } = useSelector(state => state.properties);
	const { invoicesSliceLoading } = useSelector(state => state.invoices);
	// effects
	useEffect(() => {
		if (!isAdmin && !id) {
			navigate('/auth/login')
		}
		else if (isAdmin && id) {
			navigate('/admin/AdminHome')
		}
		else if (!isAdmin && id) {
			navigate('/client/home')
		}
	}, [isAdmin, id])


	useEffect(() => {
		if (!isAdmin && id) {
			dispatch(allPropertiesOfClient({ id }))
			dispatch(propertyInvoicesOfClient({ id, accessToken }))
		} else if (isAdmin && id) {
			dispatch(allSereneUsers())
		}
	}, [id, isAdmin])

	useEffect(() => {
		if (location.pathname === '/auth/login' && id) {
			navigate('/client/home')
		}
	}, [location.pathname])

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={(userSliceLoading || propertiesSliceLoading || invoicesSliceLoading) ? true : false}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<ToastContainer />
			<Outlet />
		</>
	);
}

export default App;
