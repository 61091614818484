import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RedirectForm from '../../../components/RedirectForm';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateInvoice, propertyInvoicesOfClient } from '../../../store/invoiceSlice/invoiceSlice';

export default function RedirectPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessToken, id } = useSelector(state => state.user);
    const [showForm, setShowForm] = useState(false);
    const [propsData, setPropsData] = useState({});
    const [open, setOpen] = useState(true);
    const socket = io('https://dev.serenetower.com');

    const handleClose = () => {
        setOpen(false);
    };

    const { refNo, amount, brn_code,transactionType } = location.state;

    useEffect(() => {
        console.log("aaaaaaaaaaaaaaaaaaaaaa")
        axios.post('https://dev.serenetower.com/payment/makePayment', {
            refNo: `${refNo}-${new Date().getTime()}`,
            transactionAmount: `${amount}`,
            brn_code,
            registration_no: refNo,
            transactionTypeId: `${transactionType}`
        }).then(response => {
            setPropsData(response.data);
            setShowForm(true);
        })
        console.log("eeeeeeeeeeeeee")
        socket.on('paymentStatus', (data) => {
            console.log("ttttttttttttttt")
            handleClose();
            navigate('/client/PaymentInvoice')
            toast.info(`APG ${data.response.TransactionStatus}`, {
                position: "bottom-left"
            });
            console.log(data.response.TransactionStatus)
            console.log("lllllllllllllllllllll")
            if (data.response.TransactionStatus === 'Paid') {
                dispatch(updateInvoice({ brn_code, registration_no: refNo, paid_amt: amount, accessToken })).then(response => {
                    if (!response.error) {
                        dispatch(propertyInvoicesOfClient({ id, accessToken }))
                    }
                })
            }else if(data.response.TransactionStatus === 'Failed'){
                toast.info(`Transaction Failed`, {
                    position: "bottom-left",
                    style: {
                        backgroundColor: '#f8d7da',  // Light red background
                        color: '#721c24',            // Dark red text
                    },
                });
            }else{
                toast.info(`Transaction Failed`, {
                    position: "bottom-left",
                    style: {
                        backgroundColor: '#f8d7da',  // Light red background
                        color: '#721c24',            // Dark red text
                    },
                });
            }
        })
        return () => {
            console.log('Disconnecting socket');
            socket.disconnect();
        };
    }, [])
    return (
        <>
            {showForm && <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <RedirectForm props={propsData.data} />
            </>
            }
        </>
    )
}
