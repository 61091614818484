import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedProperty } from '../store/propertiesSlice/propertiesSlice';
import { propertyInvoicesOfClient } from '../store/invoiceSlice/invoiceSlice';

const columns = [
	{ id: 'Registration_no', label: 'Registration Number', minWidth: 170 },
	{ id: 'block_no', label: 'Floor Number', minWidth: 100, format: (value) => value.toLocaleString('en-US') },
	{
		id: 'plot_no',
		label: 'Unit Number',
		minWidth: 170,
		format: (value) => value.toFixed(2),
	},
	{
		id: 'payment_code',
		label: 'Payment Code',
		minWidth: 170,
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'TotalPrice',
		label: 'Price',
		minWidth: 170,
		format: (value) => value.toFixed(2),
	},
	{
		id: 'booking_date',
		label: 'Booking Date',
		minWidth: 170,
		format: (value) => value.toFixed(2),
	},
	{
		id: '',
		label: '',
		minWidth: 170,
		format: (value) => value.toLocaleString('en-US'),
	},
];

export default function ProductList() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// global
	const { properties } = useSelector(state => state.properties);
	const { isAdmin, accessToken, selectedUser } = useSelector(state => state.user)
	
	// local
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	// functions
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const handleViewInvoices = (registrationNumber) => {
		if (isAdmin) {
			dispatch(setSelectedProperty(registrationNumber))
			dispatch(propertyInvoicesOfClient({ id:selectedUser, accessToken }))
			navigate('/admin/userPaymentInvoice')
		}
		else {
			dispatch(setSelectedProperty(registrationNumber))
			navigate('/client/paymentInvoice') 
		}
	}

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative', zIndex: 0 }}>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									sx={{
										fontWeight: 'bold',
										color: '#0e1123',
										minWidth: column.minWidth
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{properties
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.Registration_no}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<TableCell key={column.id} align={column.align}>
													{column.id === '' ? (
														<>
															{value === 'paid' ? (
																<Button disabled>PAID</Button>
															) : (
																<Button
																	variant="contained"
																	color='success'
																	onClick={() => handleViewInvoices(row.Registration_no)}
																>
																	View Invoices
																</Button>
															)}
														</>
													) : (
														<>
															{column.format && typeof value === 'number'
																? column.format(value)
																: value}
														</>
													)}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10]}
				component="div"
				count={properties.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}