import React from 'react'
import PaymentInvoiceList from '../../../components/PaymentInvoiceList'

export default function PaymentInvoice({ theme }) {

	return (
		<div>
			<PaymentInvoiceList />
		</div>
	)
}
