import React from 'react'
import ProductList from '../../../components/ProductList'

export default function UserProperties({ theme }) {

	return (
		<div>
			<ProductList/>
		</div>
	)
}