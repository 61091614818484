import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';

export default function PaymentForm() {
    const location = useLocation()
    const navigate = useNavigate()
    const data = location.state.data;
    const [amountToBePaid, setAmountToBePaid] = useState(data.amountToBePaid);
    const selectedUser = useSelector(state => state?.user?.selectedUser)
    const selectedProperty = useSelector(state => state?.properties?.selectedProperty)
    const [transactionType, setTransactionType] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!transactionType) {
            alert('Please select a transaction type.');
            return;
        }

        navigate('/Client/RedirectPage', {
            state: {
                refNo: data.registration_no,
                amount: amountToBePaid,
                brn_code: data.brn_code,
                transactionType: transactionType
            }
        })
    };

    return (
        <>
            <div className="tw-px-4 tw-py-2">
                <div className='tw-bg-white tw-shadow-md tw-rounded-lg tw-p-8 tw-w-full'>
                    <h2 className='tw-text-2xl tw-font-semibold tw-mb-6'>Detail Screen</h2>
                    <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6 tw-w-full'>
                        <div className='tw-mb-2'>
                            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>User CNIC:</strong>
                            <p>{selectedUser}</p>
                        </div>
                        <div className='tw-mb-2'>
                            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Property Registration Number:</strong>
                            <p>{selectedProperty}</p>
                        </div>
                        <div className='tw-mb-2'>
                            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Installment Number:</strong>
                            <p>{data.inst_no}</p>
                        </div>
                        <div className='tw-mb-2'>
                            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Payment Type:</strong>
                            <p>{data.payment_type}</p>
                        </div>
                        <div className='tw-mb-2'>
                            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Amount:</strong>
                            <input 
                                type="number" 
                                value={amountToBePaid} 
                                onChange={(event) => setAmountToBePaid(event.target.value)} 
                                className='tw-border-[0.5px] tw-border-black tw-p-2 tw-w-full'
                            />
                        </div>
                        <div className='tw-mb-2'>
                            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Transaction Type:</strong>
                            <select 
                                autoComplete="off" 
                                id="TransactionTypeId" 
                                name="TransactionTypeId" 
                                value={transactionType} 
                                onChange={(e) => setTransactionType(e.target.value)}
                                className='tw-border-[0.5px] tw-border-black tw-p-2 tw-w-full'
                            >
                                <option value="">Select Transaction Type</option>
                                <option value="1">Alfa Wallet</option>
                                <option value="2">Alfalah Bank Account</option>
                                <option value="3">Credit/Debit Card</option>
                            </select>
                        </div>
                    </div>

                    <button type='submit' onClick={handleSubmit} className='tw-bg-blue-500 tw-text-white tw-font-semibold tw-rounded-md tw-py-2 tw-px-4 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 tw-w-full'>Submit Payment</button>
                </div>
            </div>
        </>
    )
}
