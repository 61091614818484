import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const sereneBaseUrl = "https://dashboard.serenetower.com";

const initialState = {
    propertiesSliceLoading: false,
    selectedProperty: null,
    properties: []
}

export const propertiesSlice = createSlice({
    name: 'propertiesSlice',
    initialState,
    reducers: {
        setSelectedProperty(state, action) {
            state.selectedProperty = action.payload
        },
        setProperties: (state, action) => {
            state.selectedProperty = null;
            state.properties = [];
        },
    },
    extraReducers: (builder) => {
        // all properties of client
        builder.addCase(allPropertiesOfClient.pending, (state, action) => {
            state.propertiesSliceLoading = true
        })
        builder.addCase(allPropertiesOfClient.fulfilled, (state, action) => {
            state.properties = action.payload.data;
            state.propertiesSliceLoading = false;
        })
        builder.addCase(allPropertiesOfClient.rejected, (state, action) => {
            state.propertiesSliceLoading = false;
            toast.error("Couldn't fetch user properties!", {
                position: "bottom-left"
            });
        })
    }
})

export const allPropertiesOfClient = createAsyncThunk('propertiesSlice/allPropertiesOfClient', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${sereneBaseUrl}/api/MobileApp/allfilesclient`, {
            params: {
                client_id: userData.id
            },
            headers: {
                "Content-Type": "application/json",
                Api_key: "zCPmR1HxL0CdaGfO%2faxXcA%3d%3d",
            },
        })
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const { setSelectedProperty, setProperties } = propertiesSlice.actions;