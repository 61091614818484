import React from 'react'
import { useSelector } from 'react-redux'

function Profile() {
  const userCNIC = useSelector(state => state?.user?.cnic)
  const userMobileNumber = useSelector(state => state?.user?.mobileNumber)
  const userName = useSelector(state => state?.user?.memberName)
  const userFatherName = useSelector(state => state?.user?.fatherName)
  const userAddress = useSelector(state => state?.user?.address)
  const userCountry = useSelector(state => state?.user?.country)
  const userCity = useSelector(state => state?.user?.city)
  return (
    <>
     <div className='tw-px-4 tw-py-2'>
      <div className='tw-bg-white tw-shadow-md tw-rounded-lg tw-p-8 '>
        <h2 className='tw-text-2xl tw-font-semibold tw-mb-6'>User Profile</h2>
        <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6'>
          <div className='tw-mb-2'>
            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Name:</strong>
            <p>{userName}</p>
          </div>
          <div className='tw-mb-2'>
            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Father's Name:</strong>
            <p>{userFatherName}</p>
          </div>
          <div className='tw-mb-2'>
            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>CNIC:</strong>
            <p>{userCNIC}</p>
          </div>
          <div className='tw-mb-2'>
            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Mobile Number:</strong>
            <p>{userMobileNumber}</p>
          </div>
          <div className='tw-mb-2'>
            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Address:</strong>
            <p>{userAddress}</p>
          </div>
          <div className='tw-mb-2'>
            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>Country:</strong>
            <p>{userCountry}</p>
          </div>
          <div className='tw-mb-2'>
            <strong className='tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2'>City:</strong>
            <p>{userCity}</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Profile