import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const columns = [
	{ id: 'inst_no', label: 'Installment Number', minWidth: 170 },
	{ id: 'payment_type', label: 'Payment Type', minWidth: 100, format: (value) => value.toLocaleString('en-US') },
	{
		id: 'inst_date',
		label: 'Installment Date',
		minWidth: 170,
		format: (value) => value.toFixed(2),
	},
	{
		id: 'inst_amt',
		label: 'Installment Amount',
		minWidth: 170,
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'paid_amt',
		label: 'Paid Amount',
		minWidth: 170,
		format: (value) => value.toFixed(2),
	},
	{
		id: 'remaining_amt',
		label: 'Remaining Amount',
		minWidth: 170,
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'actions',
		label: '',
		minWidth: 170,
	},
];

export default function ProductList() {
	const navigate = useNavigate();

	// global
	const { invoices } = useSelector(state => state.invoices);
	const { selectedProperty } = useSelector(state => state.properties);
	const { isAdmin } = useSelector(state => state.user)

	// local
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const data = invoices.filter(invoice => invoice.registration_no.toLowerCase() === selectedProperty.toLowerCase()).map(invoice => {
		const currentIndex = invoices.indexOf(invoice);
		return {
			...invoice,
			remaining_amt: (invoice.inst_amt - invoice.paid_amt).toFixed(2), // Calculate remaining amount
			disabled: (currentIndex === 0 && invoices[currentIndex].paid_amt !== invoices[currentIndex].inst_amt) ? false : invoices[currentIndex === 0 ? 0 : currentIndex - 1].paid_amt !== invoices[currentIndex === 0 ? 0 : currentIndex - 1].inst_amt
		}
	})
	// functions
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const handleViewInvoices = (data) => { 
		navigate('/client/paymentPage', {
			state: { data }
		})
	}

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative', zIndex: 0 }}>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									sx={{
										fontWeight: 'bold',
										color: '#0e1123',
										minWidth: column.minWidth
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map(row => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={`${row.inst_no}-${row.payment_type}`}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<TableCell key={column.id} align={column.align}>
													{column.id === 'actions' ? (
														<>
															{row.inst_amt === row.paid_amt ? (
																<Button disabled>PAID</Button>
															) : (
																<>
																	{isAdmin ? (
																		<Button disabled>Un-paid</Button>
																	) : (
																		<Button
																			variant="contained"
																			color='success'
																			onClick={() => handleViewInvoices({ inst_no: row.inst_no, payment_type: row.payment_type, amountToBePaid: row.inst_amt - row.paid_amt, registration_no: row.registration_no, brn_code: row.brn_code })}
																			disabled={row.disabled}
																		>
																			Make Payment
																		</Button>
																	)}
																</>
															)}
														</>
													) : (
														<>
															{column.format && typeof value === 'number'
																? column.format(value)
																: value}
														</>
													)}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10]}
				component="div"
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}
