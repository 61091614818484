import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { login } from '../../../store/userSlice/userSlice';
import { useNavigate } from 'react-router-dom';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundImage: `url(${require('../../../assets/background-image.jpg')})`,
	overflow: 'hidden',  // Prevent scrolling
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
	textAlign: 'center',
	backgroundColor: 'rgba(0, 0, 0, 0.75)',
	padding: theme.spacing(8, 4),  // Decreased top padding from 14 to 8
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(6, 1),
	},
	[theme.breakpoints.down('xs')]: {
		padding: theme.spacing(3, 0.5),
	},
	borderRadius: '8px',
	boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	backgroundColor: '#d4af37',
	color: '#0e1123',
	padding: theme.spacing(1.2),
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1),
	},
	borderRadius: '10px',
	textTransform: 'none',
	'&:hover': {
		backgroundColor: '#0e1123',
		color: '#fff'
	},
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#fff',
		borderRadius: '10px',
	},
	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: '#fff',
		borderRadius: '10px',
	},
	'& .MuiInputLabel-root': {
		color: '#fff',
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: '#fff',
	},
	'& .MuiInputBase-input::placeholder': {
		color: '#fff',
		opacity: 1,
	},
	'& .MuiOutlinedInput-root': {
		color: '#fff',
	},
	[theme.breakpoints.down('sm')]: {
		'& .MuiInputBase-input': {
			fontSize: '0.9rem',
		},
	},
}));

const ClickableIcon = ({ onClick, iconSrc, altText, link }) => {
	return (
		<a href={link} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '5px' }}>
			<div onClick={onClick}>
				<img src={iconSrc} alt={altText} style={{ width: '80px', cursor: 'pointer' }} />
			</div>
		</a>
	);
};

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [form, setForm] = useState({
		username: '',
		password: ''
	});
	const [errors, setErrors] = useState({});

	const handleChange = (prop) => (event) => {
		setErrors({ ...errors, [prop]: '' });
		setForm({ ...form, [prop]: event.target.value });
	};

	const handleLogin = () => {
		if (validateForm()) {
			dispatch(login({ CNIC: form.username, password: form.password }));
		}
	};

	const navigateToSignUp = () => {
		navigate('/auth/signup');
	};

	const navigateToForgotPassword = () => {
		navigate('/auth/forgotpassword');
	};

	const validateForm = () => {
		let isValid = true;
		const newErrors = {};
		if (!form.username) {
			newErrors.username = 'Username is required';
			isValid = false;
		}
		if (!form.password) {
			newErrors.password = 'Password is required';
			isValid = false;
		} else if (form.password.length < 8) {
			newErrors.password = 'Password must be at least 8 characters';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	return (
		<StyledGridContainer container>
			<StyledGridItem item xs={12} sm={6} md={4} lg={3}>
				<img src={require('../../../assets/logo.png')} alt="logo" style={{ width: '30%' }} className='tw-mx-auto tw-mb-11' />
				<StyledTextField
					label="CNIC"
					value={form.username}
					onChange={handleChange('username')}
					margin="normal"
					fullWidth
				/>
				{errors.username && (
					<Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
						{errors.username}
					</Typography>
				)}
				<StyledTextField
					label="Password"
					type="password"
					value={form.password}
					onChange={handleChange('password')}
					margin="normal"
					fullWidth
				/>
				{errors.password && (
					<Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
						{errors.password}
					</Typography>
				)}
				<Typography variant="h6" sx={{ color: '#d4af37', display: 'inline', cursor: 'pointer', fontSize: { xs: '0.875rem' }, textDecoration: 'underline', ml: 0.5 }}
					onClick={() => navigateToForgotPassword()}>
					Forgot Password?
				</Typography>
				<StyledButton
					variant="contained"
					onClick={handleLogin}
					fullWidth
					sx={{ mt: 3 }}
				>
					Login
				</StyledButton>
				<Grid container justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
					<Typography variant="h6" sx={{ color: '#fff', display: 'inline', fontSize: '0.875rem', marginRight: '4px' }}>
						Don't have an account?
					</Typography>
					<Typography variant="h6" sx={{ color: '#d4af37', display: 'inline', cursor: 'pointer', fontSize: { xs: '0.875rem' }, textDecoration: 'underline', ml: 0.5 }}
						onClick={() => navigateToSignUp()}>
						SignUp
					</Typography>
				</Grid>
				<Grid container direction="column" alignItems="center" sx={{ mt: 4 }}> {/* Increased the spacing here */}
					<Typography variant="h6" sx={{ marginLeft: '0px', color: '#fff', cursor: 'pointer', textDecoration: 'none', fontSize: { xs: '0.6rem', sm: '0.6rem', md: '0.6rem' }, mr: 2 }}
						onClick={() => window.open('https://mim.archi/', '_blank')}>
						Developed By
					</Typography>
					<ClickableIcon
						onClick={() => console.log('Icon clicked')}
						iconSrc={require('../../../assets/logoforapps.png')}
						altText="Clickable icon"
						link="https://mim.archi/"
					/>
					<Typography variant="h6" sx={{  marginRight: '5px',color: '#fff', cursor: 'pointer', textDecoration: 'none', fontSize: { xs: '0.6rem', sm: '0.6rem', md: '0.6rem' }, mt: 0 }}
						onClick={() => window.open('https://mim.archi/', '_blank')}>
						www.mim.archi
					</Typography>
				</Grid>
			</StyledGridItem>
		</StyledGridContainer>
	);
};

export default Login;
