import { configureStore } from '@reduxjs/toolkit';

// slices
import { userSlice } from './userSlice/userSlice';
import { propertiesSlice } from './propertiesSlice/propertiesSlice';
import { invoiceSlice } from './invoiceSlice/invoiceSlice'; 

export default configureStore({
    reducer: {
        user: userSlice.reducer,
        properties: propertiesSlice.reducer,
        invoices: invoiceSlice.reducer,
    }
});