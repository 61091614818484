import React from 'react'
import Box from '@mui/material/Box';

export default function About() {
	return (
		<>
			<Box height={30} />
			<Box sx={{ display: "flex" }}>
				<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
					<h1>Invoice</h1>
				</Box>
			</Box>
		</>
	)
}