import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { useDispatch } from 'react-redux';
import { logout } from '../store/userSlice/userSlice'
import { setProperties } from '../store/propertiesSlice/propertiesSlice'
import { setInvoices } from '../store/invoiceSlice/invoiceSlice'
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { useNavigate } from 'react-router-dom'

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

export default function NavBar(props) {
	const navigate = useNavigate()
	const theme = useTheme();
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const blue = {
		50: '#F0F7FF',
		100: '#C2E0FF',
		200: '#99CCF3',
		300: '#66B2FF',
		400: '#3399FF',
		500: '#007FFF',
		600: '#0072E6',
		700: '#0059B3',
		800: '#004C99',
		900: '#003A75',
	};

	const grey = {
		50: '#F3F6F9',
		100: '#E5EAF2',
		200: '#DAE2ED',
		300: '#C7D0DD',
		400: '#B0B8C4',
		500: '#9DA8B7',
		600: '#6B7A90',
		700: '#434D5B',
		800: '#303740',
		900: '#1C2025',
	};

	const MenuItem = styled(BaseMenuItem)(
		({ theme }) => `
		list-style: none;
		padding: 8px;
		border-radius: 8px;
		cursor: default;
		user-select: none;
	  
		&:last-of-type {
		  border-bottom: none;
		}
	  
		&:focus {
		  outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
		  background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
		  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
		}
	  
		&.${menuItemClasses.disabled} {
		  color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
		}
		`,
	);

	const MenuButton = styled(BaseMenuButton)(
		({ theme }) => `
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 600;
		font-size: 0.875rem;
		line-height: 1.5;
		padding: none;
		border-radius: none;
		color: white;
		transition: all 150ms ease;
		cursor: pointer;
		background:none,
		color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
		box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
	  
		&:focus-visible {
		  box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
		  outline: none;
		}
		`,
	);
	const Listbox = styled('ul')(
		({ theme }) => `
		font-family: 'IBM Plex Sans', sans-serif;
		font-size: 0.875rem;
		box-sizing: border-box;
		padding: 6px;
		margin: 12px 0;
		min-width: 200px;
		border-radius: 12px;
		overflow: auto;
		outline: 0px;
		background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
		border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
		color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
		box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
			};
		z-index: 1;
		`,
	);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const createHandleMenuClick = (menuItem) => {
		return () => {
			if (menuItem === 'Profile') {
				navigate('/client/profile')
			}
			// console.log(`Clicked on ${menuItem}`);
		};
	};
	const Logout = () => {
		dispatch(logout())
		dispatch(setProperties())
		dispatch(setInvoices())
	}

	const navigateToHome = (menuItem) => {
		navigate('/client/home')
	}
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar position="fixed" open={open} sx={{ backgroundColor: "#0e1123" }}>
				<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{
								marginRight: 5,
								...(open && { display: 'none' }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" noWrap component="div">
							Serene Tower
						</Typography>
					</Box>
					<Dropdown>
						<MenuButton><AccountCircleOutlined
							sx={{ color: 'white', fontSize: '2.5rem' }}
						/></MenuButton>
						<Menu slots={{ listbox: Listbox }}>
							<MenuItem onClick={createHandleMenuClick('Profile')}>Profile</MenuItem>
							<MenuItem onClick={() => Logout()}>Log out</MenuItem>
						</Menu>
					</Dropdown>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open} >
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					{['Properties'].map((text, index) => (
						<ListItem key={text} disablePadding sx={{ display: 'block' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								onClick={() => navigateToHome()}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									{index % 2 === 0 ? <HomeIcon /> : <MailIcon />}
								</ListItemIcon>
								<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
				<Divider />
				<List>
					{['Logout'].map((text, index) => (
						<ListItem key={text} disablePadding sx={{ display: 'block' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								onClick={() => Logout()}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									{index % 2 === 0 ? <LogoutIcon /> : <MailIcon />}
								</ListItemIcon>
								<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				<DrawerHeader />
				{props.children}
			</Box>
		</Box>
	);
}
