import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../../store/userSlice/userSlice';
import { useNavigate } from 'react-router-dom';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${require('../../../assets/background-image.jpg')})`,
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    padding: theme.spacing(14, 4),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(6, 1),
    },
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3, 0.5),
    },
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#d4af37',
    color: '#0e1123',
    padding: theme.spacing(1.2),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
    borderRadius: '10px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#0e1123',
        color: '#fff'
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
        borderRadius: '10px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
        borderRadius: '10px',
    },
    '& .MuiInputLabel-root': {
        color: '#fff',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#fff',
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#fff',
        opacity: 1,
    },
    '& .MuiOutlinedInput-root': {
        color: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
        '& .MuiInputBase-input': {
            fontSize: '0.9rem',
        },
    },
}));

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [form, setForm] = useState({
        cnic: '',
        password: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (prop) => (event) => {
        setErrors({ ...errors, [prop]: '' });
        setForm({ ...form, [prop]: event.target.value });
    };

    const handleResetPassword = async () => {
        if (validateForm()) {
            const resultAction = await dispatch(forgotPassword({ CNIC: form.cnic, password: form.password }));
            if (forgotPassword.fulfilled.match(resultAction)) {
                navigate('/auth/login'); // Navigate to login on success
            }
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        if (!form.cnic) {
            newErrors.cnic = 'CNIC is required';
            isValid = false;
        }
        if (!form.password) {
            newErrors.password = 'New password is required';
            isValid = false;
        } else if (form.password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    return (
        <StyledGridContainer container>
            <StyledGridItem item xs={12} sm={6} md={4} lg={3}>
                <img src={require('../../../assets/logo.png')} alt="logo" style={{ width: '30%' }} className='tw-mx-auto tw-mb-11' />
                <Typography variant="h5" sx={{ color: 'grey', marginBottom: '16px' }}>
                    Reset Password
                </Typography>
                <StyledTextField
                    label="CNIC"
                    value={form.cnic}
                    onChange={handleChange('cnic')}
                    margin="normal"
                    fullWidth
                />
                {errors.cnic && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.cnic}
                    </Typography>
                )}
                <StyledTextField
                    label="New Password"
                    type="password"
                    value={form.password}
                    onChange={handleChange('password')}
                    margin="normal"
                    fullWidth
                />
                {errors.password && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.password}
                    </Typography>
                )}
                <StyledButton
                    variant="contained"
                    onClick={handleResetPassword}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Reset Password
                </StyledButton>
            </StyledGridItem>
        </StyledGridContainer>
    );
};

export default ForgotPassword;
