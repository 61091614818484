import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { signUp } from '../../../store/userSlice/userSlice';
import { useNavigate } from 'react-router-dom';
import { getData } from 'country-list';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${require('../../../assets/background-image.jpg')})`,
    overflow: 'hidden', // Prevent scrolling
}));

const ClickableIcon = ({ onClick, iconSrc, altText, link }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center' }}>
            <div onClick={onClick}>
                <img src={iconSrc} alt={altText} style={{ width: '60px', cursor: 'pointer' }} />
            </div>
        </a>
    );
};

const StyledGridItem = styled(Grid)(({ theme }) => ({
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    padding: theme.spacing(2, 4),
    maxWidth: '400px', // Set a maximum width to avoid overflow
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2),
        maxWidth: '90%',
    },
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 1),
    },
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#d4af37',
    color: '#0e1123',
    textTransform: 'none',
    padding: theme.spacing(1),
    borderRadius: '10px',
    fontSize: '1rem',
    '&:hover': {
        backgroundColor: '#0e1123',
        color: '#fff'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        padding: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '0.7rem',
        padding: theme.spacing(0.4),
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
        borderRadius: '10px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
        borderRadius: '10px',
    },
    '& .MuiInputLabel-root': {
        color: '#fff',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#fff',
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#fff',
        opacity: 1,
    },
    '& .MuiOutlinedInput-root': {
        color: '#fff',
    },
    '& option': {
        backgroundColor: 'white',
        color: 'black',
    },
    'MuiInputBase-input': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
    },
    marginBlock: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        marginBlock: theme.spacing(0.5),
    }
}));

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState({
        cnic: '',
        email: '',
        username: '',
        fatherName: '',
        mobileNumber: '',
        country: '',
        city: '',
        address: '',
        password: ''
    });
    const [errors, setErrors] = useState({});

    const countryOptions = getData().map(country => ({
        label: country.name,
        value: country.code
    }));

    const handleChange = (prop) => (event) => {
        setErrors({ ...errors, [prop]: '' });
        setForm({ ...form, [prop]: event.target.value });
    };

    const navigateToLogin = () => {
        navigate('/auth/login')
    }

    const handleSignUp = () => {
        if (validateForm()) {
            dispatch(signUp(form))
            setForm({
                cnic: '',
                email: '',
                username: '',
                fatherName: '',
                mobileNumber: '',
                country: '',
                city: '',
                address: '',
                password: ''
            });
        }
    }

    const validateForm = () => {
        const newErrors = {};
        if (!form.cnic) newErrors.cnic = 'CNIC is required';
        if (!form.email) {
            newErrors.email = 'Email address is required';
        } else if (!/\S+@\S+\.\S+/.test(form.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!form.username) newErrors.username = 'Username is required';
        if (!form.fatherName) newErrors.fatherName = 'Father\'s name is required';
        if (!form.mobileNumber) newErrors.mobileNumber = 'Mobile number is required';
        if (!form.country) newErrors.country = 'Country is required';
        if (!form.city) newErrors.city = 'City is required';
        if (!form.address) newErrors.address = 'Address is required';
        if (!form.password) {
            newErrors.password = 'Password is required';
        } else if (form.password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <StyledGridContainer container>
            <StyledGridItem item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" sx={{ color: '#fff', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem' } }}>Sign Up</Typography>
                <Typography variant="h6" sx={{ color: '#fff', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem' } }}>Create a new account</Typography>
                <StyledTextField label="Username" value={form.username} onChange={handleChange('username')} size="small" fullWidth />
                {errors.username && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.username}
                    </Typography>
                )}
                <StyledTextField label="Email Address" value={form.email} onChange={handleChange('email')} size="small" fullWidth />
                {errors.email && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.email}
                    </Typography>
                )}
                <StyledTextField label="CNIC" value={form.cnic} onChange={handleChange('cnic')} size="small" fullWidth />
                {errors.cnic && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.cnic}
                    </Typography>
                )}
                <StyledTextField label="Father's Name" value={form.fatherName} onChange={handleChange('fatherName')} size="small" fullWidth />
                {errors.fatherName && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.fatherName}
                    </Typography>
                )}
                <StyledTextField label="Mobile Number" value={form.mobileNumber} onChange={handleChange('mobileNumber')} size="small" fullWidth />
                {errors.mobileNumber && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.mobileNumber}
                    </Typography>
                )}
                <Grid container spacing={{ xs: 0, sm: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField
                            select
                            value={form.country}
                            onChange={handleChange('country')}
                            size="small"
                            fullWidth
                            SelectProps={{
                                native: true,
                                IconComponent: (props) => <ArrowDropDownIcon {...props} style={{ color: 'white' }} />,
                            }}
                        >
                            {countryOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </StyledTextField>
                        {errors.country && (
                            <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                                {errors.country}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField label="City" value={form.city} onChange={handleChange('city')} size="small" fullWidth />
                        {errors.city && (
                            <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                                {errors.city}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <StyledTextField label="Address" value={form.address} onChange={handleChange('address')} size="small" fullWidth />
                {errors.address && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.address}
                    </Typography>
                )}
                <StyledTextField label="Password" type="password" value={form.password} onChange={handleChange('password')} size="small" fullWidth />
                {errors.password && (
                    <Typography variant="caption" sx={{ color: 'red', textAlign: 'left', display: 'block' }}>
                        {errors.password}
                    </Typography>
                )}
                <StyledButton variant="contained" onClick={handleSignUp} fullWidth>
                    Register
                </StyledButton>
                <Grid sx={{ mt: 1, width: '100%', textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ color: '#fff', display: 'inline', fontSize: { xs: '0.7rem', sm: '0.75rem', md: '0.875rem' } }}>
                        Already have an account?
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#d4af37', display: 'inline', cursor: 'pointer', textDecoration: 'none', ml: 0.5, fontSize: { xs: '0.7rem', sm: '0.75rem', md: '0.875rem' } }}
                        onClick={() => navigateToLogin()}>
                        Login
                    </Typography>
                </Grid>
                <Typography variant="h6" sx={{ color: '#fff', display: 'inline', cursor: 'pointer', textDecoration: 'none', mt: 0.5, fontSize: { xs: '0.6rem', sm: '0.6rem', md: '0.6rem' } }}
                    onClick={() => window.open('https://mim.archi/', '_blank')}>
                    Developed By
                </Typography>
                <Grid container justifyContent="center">
                    <ClickableIcon
                        onClick={() => console.log('Icon clicked')}
                        iconSrc={require('../../../assets/logoforapps.png')}
                        altText="Clickable icon"
                        link="https://mim.archi/"
                    />
                </Grid>
                <Typography variant="h6" sx={{ color: '#fff', display: 'inline', cursor: 'pointer', textDecoration: 'none', mt: 0.5, fontSize: { xs: '0.6rem', sm: '0.6rem', md: '0.6rem' } }}
                    onClick={() => window.open('https://mim.archi/', '_blank')}>
                    https://mim.archi/
                </Typography>
            </StyledGridItem>
        </StyledGridContainer>
    );
};

export default SignUp;
