import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const sereneBaseUrl = "https://dashboard.serenetower.com";

const initialState = {
    invoiceSliceLoading: false,
    invoices: []
}

export const invoiceSlice = createSlice({
    name: 'invoiceSlice',
    initialState,
    reducers: {
        setInvoices: (state, action) => {
            state.invoices = [];
        },
    },
    extraReducers: (builder) => {
        // all properties of client
        builder.addCase(propertyInvoicesOfClient.pending, (state, action) => {
            state.invoiceSliceLoading = true
        })
        builder.addCase(propertyInvoicesOfClient.fulfilled, (state, action) => {
            state.invoices = action.payload.data;
            state.invoiceSliceLoading = false;
        })
        builder.addCase(propertyInvoicesOfClient.rejected, (state, action) => {
            state.invoiceSliceLoading = false;
            toast.error("Couldn't fetch user invoices!", {
                position: "bottom-left"
            });
        })

        // update invoice
        builder.addCase(updateInvoice.pending, (state, action) => {
            state.invoiceSliceLoading = true
        })
        builder.addCase(updateInvoice.fulfilled, (state, action) => {
            state.invoiceSliceLoading = false;
            toast.success("Invoice updated successfully!", {
                position: "bottom-left"
            });
        })
        builder.addCase(updateInvoice.rejected, (state, action) => {
            state.invoiceSliceLoading = false;
            toast.error("Couldn't update invoice!", {
                position: "bottom-left"
            });
        })
    }
})

export const propertyInvoicesOfClient = createAsyncThunk('invoiceSlice/propertyInvoicesOfClient', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${sereneBaseUrl}/api/MobileApp/GetInvoicesDetailByClient?client_id=${userData.id}`, {
        }, {
            headers: {
                // Authorization: `Bearer ${userData.accessToken}`
                "Content-Type": "application/json",
                API_KEY: "zCPmR1HxL0CdaGfO%2faxXcA%3d%3d",
            }
        })

        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const updateInvoice = createAsyncThunk('invoiceSlice/updateInvoice', async ({ brn_code, registration_no, paid_amt, accessToken }, { rejectWithValue }) => {
    try {
        const response = await axios.post('https://dashboard.serenetower.com/api/MobileApp/PostInvoice', {
            brn_code,
            registration_no,
            // id_no: "",
            // mobile_no: "",
            // registration_status: "",
            // inst_no: "7",
            // payment_type: "INST",
            // inst_date: "",
            // receipt_no: "",
            // receipt_status: "",
            paid_date: `${new Date().toISOString().split('T')[0]}`,
            // inst_amt: "",
            paid_amt,
            payment_mode: "Online",
            // payment_nature: "",
            // payment_nature3: ""
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                API_Key: 'zCPmR1HxL0CdaGfO%2faxXcA%3d%3d'

            }
        })

        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const { setInvoices } = invoiceSlice.actions;