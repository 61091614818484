import React from 'react'
import ProductList from '../../../components/ProductList'

export default function Home({ theme }) {

	return (
		<div>
			<ProductList />
		</div>
	)
}
