import React from 'react'
import UserList from '../../../components/UserList'

export default function AdminHome({ theme }) {

	return (
		<div>
			<UserList />
		</div>
	)
}