import React, { useState, useRef } from 'react'

export default function RedirectForm({ props }) {
    const [formData, setFormData] = useState(props);
    console.log(formData)
 
    const formRef = useRef(null);
    setTimeout(() => {
        formRef.current.submit();
    }, 1000)
    
    return (
        <>
            <form
                ref={formRef}
                action="https://payments.bankalfalah.com/SSO/SSO/SSO"
                id="PageRedirectionForm"
                method="post"
                noValidate="novalidate"
                target="_blank"
                className='tw-hidden'
            >
                <input id="AuthToken" name="AuthToken" value={formData.AuthToken} />
                <input id="RequestHash" name="RequestHash" value={formData.RequestHash} />
                <input id="ChannelId" name="ChannelId" value={formData.ChannelId} />
                <input id="Currency" name="Currency" value={formData.Currency} />
                <input id="IsBIN" name="IsBIN" value={formData.IsBIN} />
                <input id="ReturnURL" name="ReturnURL" value={formData.ReturnURL} />
                <input id="MerchantId" name="MerchantId" value={formData.MerchantId} />
                <input id="StoreId" name="StoreId" value={formData.StoreId} />
                <input id="MerchantHash" name="MerchantHash" value={formData.MerchantHash} />
                <input id="MerchantUsername" name="MerchantUsername" value={formData.MerchantUsername} />
                <input id="MerchantPassword" name="MerchantPassword" value={formData.MerchantPassword} />
                <select autoComplete="off" id="TransactionTypeId" name="TransactionTypeId" value={formData.TransactionTypeId}>
                    <option value="">Select Transaction Type</option>
                    <option value={1}>Alfa Wallet</option>
                    <option value={2}>Alfalah Bank Account</option>
                    <option value={3}>Credit/Debit Card</option>
                </select>
                <input autoComplete="off" id="TransactionReferenceNumber" name="TransactionReferenceNumber" placeholder="Order ID" type="text" value={formData.TransactionReferenceNumber} />
                <input autoComplete="off" id="TransactionAmount" name="TransactionAmount" placeholder="Transaction Amount" type="text" value={formData.TransactionAmount} />
                <button type="submit" className="btn btn-custon-four btn-danger" id="run">
                    RUN
                </button>
            </form>

        </>
    )
}
