import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useSelector ,useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedUser } from '../store/userSlice/userSlice';
import { allPropertiesOfClient } from '../store/propertiesSlice/propertiesSlice';

const allUsersData = [
    { id: 'member_name', label: 'Name', minWidth: 100, format: (value) => value.toLocaleString('en-US') },
    {
        id: 'fh_name',
        label: 'Father Name',
        minWidth: 170,
        format: (value) => value.toFixed(2),
    },
    { id: 'CNIC', label: 'CNIC', minWidth: 170 },
    {
        id: 'mobile_no',
        label: ' Phone Number',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'address',
        label: 'Address',
        minWidth: 170,
        format: (value) => value.toFixed(2),
    },
    {
        id: 'country',
        label: 'Country',
        minWidth: 170,
        format: (value) => value.toFixed(2),
    },
    {
        id: '',
        label: '',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
];

export default function UserList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // global
    const { allUsers } = useSelector(state => state.user)

    // local
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleViewInvoices = (userCNIC) => {
        dispatch(setSelectedUser(userCNIC))
        dispatch(allPropertiesOfClient({id:userCNIC}))
        navigate('/admin/userProperties')
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative', zIndex: 0 }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {allUsersData.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#0e1123',
                                        minWidth: column.minWidth
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allUsers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.CNIC}>
                                        {allUsersData.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id === '' ? (
                                                        <>
                                                            {value === 'paid' ? (
                                                                <Button disabled>PAID</Button>
                                                            ) : (
                                                                <Button
                                                                    variant="contained"
                                                                    color='success'
                                                                    onClick={() => handleViewInvoices(row.CNIC)}
                                                                >
                                                                    View Properties
                                                                </Button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </>
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={allUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}