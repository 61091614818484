import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialState = {
    userSliceLoading: false,
    accessToken: localStorage.getItem('sereneAccessToken') || null,
    isAdmin: false,
    id: '',
    registrationNumber: '',
    cnic: '',
    mobileNumber: '',
    memberName: '',
    fatherName: '',
    fatherType: '',
    address: '',
    phoneNumber: '',
    emailAddress: '',
    city: '',
    country: '',
    allUsers: [],
    selectedUser: null
}
const sereneBaseUrl = "https://dashboard.serenetower.com";
const serverUrl = "https://dev.serenetower.com";


export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        logout: (state, action) => {
            state.accessToken = null;
            localStorage.removeItem('sereneAccessToken');
            state.isAdmin = false;
            state.id = '';
            state.registrationNumber = '';
            state.cnic = '';
            state.mobileNumber = '';
            state.memberName = '';
            state.fatherName = '';
            state.fatherType = '';
            state.address = '';
            state.phoneNumber = '';
            state.emailAddress = '';
            state.city = '';
            state.country = '';
            state.allUsers = [];
            state.selectedUser = null
        },
        setId(state, action) {
            state.id = action.payload
        },
        setSelectedUser(state, action) {
            state.selectedUser = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signUp.pending, (state, action) => {
            state.userSliceLoading = true
        })
        builder.addCase(signUp.fulfilled, (state, action) => {
            state.userSliceLoading = false;
            if (action.payload.Message === 'User Already exist!') {
                toast.error("Already exists!", {
                    position: "bottom-left"
                });
            } else if(action.payload.Message === 'Not Found') {
                toast.error("User Not Found", {
                    position: "bottom-left",
                    style: {
                        backgroundColor: '#f8d7da',  // Light red background
                        color: '#721c24',            // Dark red text
                    },
                });
            }else {
                toast.success("SignUp successfully!", {
                    position: "bottom-left"
                });
            }
        })
        builder.addCase(signUp.rejected, (state, action) => {
            state.userSliceLoading = false;
            toast.error("Invalid crediential or network error!", {
                position: "bottom-left"
            });
        })
        // login
        builder.addCase(login.pending, (state, action) => {
            state.userSliceLoading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            console.log(state);
            console.log("ddddddddddddddddddd");
            console.log(action);
            state.accessToken = action.payload.accessToken;
            localStorage.setItem('sereneAccessToken', action.payload.accessToken);
            state.isAdmin = action.payload.isAdmin;
            state.id = action.payload.data.id;
            state.registrationNumber = action.payload.data.registration_no;
            state.cnic = action.payload.data.CNIC;
            state.mobileNumber = action.payload.data.mobile_no;
            state.memberName = action.payload.data.member_name;
            state.fatherName = action.payload.data.fh_name;
            state.fatherType = action.payload.data.fh_type;
            state.address = action.payload.data.address;
            state.phoneNumber = action.payload.data.phone_no;
            state.emailAddress = action.payload.data.email_address;
            state.city = action.payload.data.city;
            state.country = action.payload.data.country;
            if (!action.payload.isAdmin) {
                state.selectedUser = action.payload.data.id
            }
            state.userSliceLoading = false;
        })
        builder.addCase(login.rejected, (state, action) => {
            state.userSliceLoading = false

            toast.error("Invalid crediential or network error!", {
                position: "bottom-left"
            });
        })
        builder.addCase(allSereneUsers.pending, (state, action) => {
            state.userSliceLoading = true
        })
        builder.addCase(allSereneUsers.fulfilled, (state, action) => {
            state.allUsers = action.payload.data;
            state.userSliceLoading = false;
        })
        builder.addCase(allSereneUsers.rejected, (state, action) => {
            state.userSliceLoading = false;
            toast.error("Couldn't fetch user properties!", {
                position: "bottom-left"
            });
        })
        //forgotPasswordS
        builder.addCase(forgotPassword.pending, (state,action)=>{
            state.userSliceLoading = true;
        })
        builder.addCase(forgotPassword.fulfilled, (state,action)=>{
            state.userSliceLoading = false;
            
        })
        builder.addCase(forgotPassword.rejected, (state,action)=>{
            state.userSliceLoading = false;
            
        })
    }
})

export const login = createAsyncThunk('user/login', async (userData, { rejectWithValue }) => {
    console.log("eeeeeeeeeeeeeeee");
    console.log(userData);
    try {
        const response = await axios.post(`${serverUrl}/admin/adminLogin`, userData);
        console.log(response.data)
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${sereneBaseUrl}/api/MobileApp/forgetPassword`, userData, {
            headers: {
                'Content-Type': 'application/json',
                'API_Key': 'zCPmR1HxL0CdaGfO%2faxXcA%3d%3d'
            },
        });

        if (response.data["StatusCode"] === "200") {
            return { success: true };
        } else {
            return rejectWithValue(response.data);
        }
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const signUp = createAsyncThunk('user/signUp', async (userData, { rejectWithValue }) => {

    try {
        const response = await axios.post(`${sereneBaseUrl}/api/MobileApp/registration`, {

            cnic: userData.cnic,
            email_address: userData.email,
            user_name: userData.username,
            father_name: userData.fatherName,
            mobile_no: userData.mobileNumber,
            country: userData.country,
            city: userData.city,
            address: userData.address,
            password: userData.password,
        }, {
            headers: {
                "Content-Type": "application/json",
                "API_KEY": "zCPmR1HxL0CdaGfO%2faxXcA%3d%3d",
            },
        })
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const allSereneUsers = createAsyncThunk('allUsersSlice/allSereneUsers', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${sereneBaseUrl}/api/MobileApp/allclientsdetail`, {
            headers: {
                "Content-Type": "application/json",
                API_KEY: "zCPmR1HxL0CdaGfO%2faxXcA%3d%3d",
            },
        })
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data);
    }
})


export const { logout, setId, setSelectedUser } = userSlice.actions