import { createBrowserRouter } from 'react-router-dom';
import Auth from './pages/auth/Auth'
import SignUp from './pages/auth/signup/SignUp';
import Login from './pages/auth/login/Login';
import Client from './pages/client/Client'
import Home from './pages/client/home/Home';
import About from './pages/client/about/About';
import Profile from './pages/client/profile/Profile'
import PaymentInvoice from './pages/client/paymentInvoice/PaymentInvoice'
import Admin from './pages/admin/Admin'
import AdminHome from './pages/admin/home/AdminHome'
import UserProperties from './pages/admin/userProperties/UserProperties';
import UserPaymentInvoice from './pages/admin/userPaymenInvoice/UserPaymentInvoice';
import App from './App';
import PaymentPage from './pages/client/paymentPage/PaymentPage';
import RedirectPage from './pages/client/redirectPage/RedirectPage';
import ForgotPassword from './pages/auth/forgotpassword/ForgotPassword';

export const Router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: 'Admin',
                element: <Admin />,
                children: [
                    {
                        path: 'AdminHome',
                        element: <AdminHome />
                    },
                    {
                        path: 'UserProperties',
                        element: <UserProperties />
                    },
                    {
                        path: 'UserPaymentInvoice',
                        element: <UserPaymentInvoice />
                    },
                ]
            },
            {
                path: 'Client',
                element: <Client />,
                children: [
                    {
                        path: 'Home',
                        element: <Home />
                    },
                    {
                        path: 'About',
                        element: <About />
                    },
                    {
                        path: 'Profile',
                        element: <Profile />
                    },
                    {
                        path: 'PaymentInvoice',
                        element: <PaymentInvoice />
                    },
                    {
                        path: 'PaymentPage',
                        element: <PaymentPage />
                    },
                    {
                        path: 'RedirectPage',
                        element: <RedirectPage />
                    }
                ]
            }
            , {
                path: 'Auth',
                element: <Auth />,
                children: [
                    {
                        path: 'Login',
                        element: <Login />
                    },
                    {
                        path: 'SignUp',
                        element: <SignUp />
                    },
                    {
                        path: 'ForgotPassword',
                        element: <ForgotPassword />
                    }
                ]
            }
        ]
    }
]);
