import React from 'react';
import PaymentForm from '../../../components/PaymentForm';

export default function PaymentPage() {
    return (
        <div>
            <PaymentForm />
        </div>
    )
}
